<div class="GridDiv">
  <button mat-button [matMenuTriggerFor]="columns">
    <mat-icon>more_vert</mat-icon>
  </button>

  <mat-table
    #productTable
    [dataSource]="lines"
    [class.prodTable-M]="layoutService.isHandset$ | async"
    cdkDropList
    (cdkDropListDropped)="drop($event)"
    cdkDropListOrientation="horizontal"
    class="product-list-table"
  >
    <ng-container matColumnDef="productname">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        {{ 'PurchasingOrder.prodName' | translate }}</mat-header-cell
      >

      <mat-cell *matCellDef="let element">
        <span *ngIf="element.product">
          #{{ element.product.id }} {{ element.product.product_name }}
        </span>

        <span *ngIf="!element.product">
          <app-entity-selector-autocomplete
            ENTITY="product"
            (selectEntityItem)="fillProdlineItem($event, element)"
          >
          </app-entity-selector-autocomplete>
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="initial_qty">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        {{ 'PurchasingOrder.initial_qty' | translate }}</mat-header-cell
      >
      <mat-cell *matCellDef="let element">
        <mat-form-field>
          <input
            matInput
            [(ngModel)]="element.qty"
            type="number"
            [readonly]="true"
            [disabled]="true"
          />
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="qty">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        {{ 'PurchasingOrder.qty' | translate }}</mat-header-cell
      >
      <mat-cell *matCellDef="let element">
        <mat-form-field>
          <input
            matInput
            [(ngModel)]="element.normalized_qty"
            type="number"
            [readonly]="true"
            [disabled]="true"
          />
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="normalized_qty">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        {{ 'PurchasingOrder.normalized_qty' | translate }}</mat-header-cell
      >
      <mat-cell *matCellDef="let element">
        <mat-form-field>
          <input
            matInput
            [(ngModel)]="element.normalized_qty"
            type="number"
            [disabled]="disabled || !element.product"
          />
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="backorder">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        {{ 'ProdConfigurator.backorder' | translate }}</mat-header-cell
      >
      <mat-cell *matCellDef="let element">
        <span>{{ element.backorder }}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="supplier_name">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        {{ 'PurchasingOrder.supplier' | translate }}</mat-header-cell
      >

      <mat-cell *matCellDef="let element">
        <span *ngIf="element.supplier">
          #{{ element.supplier.id }} {{ element.supplier.supplier_name }}
          <button
            mat-icon-button
            color="warn"
            matTooltip="{{ 'PurchasingOrder.deleteSupplier' | translate }}"
            (click)="deleteProdLineSupplier(element)"
          >
            <mat-icon>delete_outline</mat-icon>
          </button>
        </span>

        <span *ngIf="!element.supplier">
          <app-entity-selector-autocomplete
            ENTITY="supplier"
            (selectEntityItem)="fillProdlineSupplier($event, element)"
          >
          </app-entity-selector-autocomplete>
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="del">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        {{ 'PurchasingOrder.del' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element; let i = index">
        <button
          mat-icon-button
          color="warn"
          (click)="removeItemLine(i)"
          [disabled]="disabled"
          *ngIf="element.product"
        >
          <mat-icon>clear</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container
      [matColumnDef]="prodFld.fld_code"
      *ngFor="let prodFld of product_fields"
    >
      <mat-header-cell *matHeaderCellDef cdkDrag>
        {{ getProdFldHeader(prodFld) }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngIf="element.product">
          {{ element.product[prodFld.fld_code] }}
        </span>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="selectedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: selectedColumns"></mat-row>
  </mat-table>

  <mat-menu #columns="matMenu">
    <mat-selection-list
      [ngModel]="selectedColumns"
      (ngModelChange)="updateSelectedColumns($event)"
      style="max-height: 70vh"
    >
      <mat-list-option
        color="primary"
        *ngFor="let column of gridCols"
        [value]="column.key"
        (click)="$event.stopPropagation()"
      >
        {{ column.desc }}
      </mat-list-option>

      <mat-divider></mat-divider>
      <div mat-subheader>{{ 'QuoteConfigurator.product' | translate }}</div>
      <mat-divider></mat-divider>
      <mat-list-option
        color="primary"
        *ngFor="let prodFld of product_fields"
        [value]="prodFld.fld_code"
        (click)="$event.stopPropagation()"
      >
        {{ getFldTranslation(prodFld) }}
      </mat-list-option>
    </mat-selection-list>
  </mat-menu>
</div>
